<template>
  <div class="main">
    <div class="main-container">
      <router-view />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../styles/layout/layoutAuth.scss';
</style>
